import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
  Contact, ResetPasswordAdminGQL,
  SearchUserInput,
  SearchUsersGQL,
  SearchUsersQuery, StandartDashboardGQL,
  User, UserAdmin,
  UserObject,
  UserPage
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {environment} from "../../../environments/environment";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
  selector: 'app-resales',
  templateUrl: './resales.component.html',
  styleUrls: ['./resales.component.scss']
})
export class ResalesComponent extends BaseComponent implements OnInit, OnDestroy {
  user: UserAdmin;
  filter: SearchUserInput = new SearchUserInput();
  model: UserPage = new UserPage();
  modelQuery: QueryRef<SearchUsersQuery>;

  labels: any = {
    // previousLabel: '<--',
    previousLabel: ' ',
    // nextLabel: '-->',
    nextLabel: ' ',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              public userService: UserService,
              private searchUsersGQL: SearchUsersGQL,
              private resetPasswordAdminGQL: ResetPasswordAdminGQL,
  ) {
    super(router,loading, translate);
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        this.user = data as UserAdmin;
        if (this.user.profile !== 'admin') {
          this.filter.gve = this.user.gve;
          this.getList();
        } else {
          this.getList();
        }
      }, error: err => super.onError(err)
    });
  }

  ngOnDestroy(): void {
    const elModalUser = $('#modalUser');
    if (elModalUser[0]) {
      elModalUser.remove();
    }
  }

  getList(): void  {
    this.modelQuery = this.searchUsersGQL.watch({input: this.filter});
    this.modelQuery.valueChanges.subscribe(({data}) => {
      this.model = data.searchUsers as UserPage;
    });
  }

  actionSearch() {
    this.modelQuery.refetch({input: this.filter});
  }

  pageChanged(event) {
    this.filter.page = event;
    this.actionSearch();
  }

  actionResetPassword(u: User) {
    this.confirmMessage('Atenção', 'Tem certeza que deseja resetar a senha da revenda <strong>' + u.companyName  + '</strong> ?', () => {
      this.resetPasswordAdminGQL.mutate({input: u.id}).subscribe(({data}) => {
        this.showMessage('Sucesso!', `Senha restaurada com sucesso!`, 'success');
      }, error => super.onError(error));
    });
  }

  getExcel() {
    this.userService.getExcelResale(this.filter).subscribe({
      next: data => {
        console.log('data');
        console.log(data);
        this.downloadHandlerFile(data,'Revendas');
      }, error: err => super.onError(err)
    });
  }
}
