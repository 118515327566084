import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    AnswerContactInput,
    AsnwerContactGQL, ChangeContactStatusGQL,
    Contact, ContactPage,
    SearchContactGQL, SearchContactInput, SearchContactQuery, SearchContactQueryVariables, UserAdmin
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import moment from 'moment';
import {ExcelService} from "../../service/excel.service";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends BaseComponent implements OnInit, OnDestroy {
    user: UserAdmin;
    modelSearchContactList: ContactPage = new ContactPage();
    modelSearchContactInput: SearchContactInput = new SearchContactInput();
    modelQuery: QueryRef<SearchContactQuery, SearchContactQueryVariables>;

    model: Contact = new Contact();

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                private searchContactGQL: SearchContactGQL,
                private asnwerContactGQL: AsnwerContactGQL,
                private changeContactStatusGQL: ChangeContactStatusGQL,
                public excelService: ExcelService
    ) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as UserAdmin;
                if (this.user.profile !== 'admin') {
                    this.router.navigate(['/resales']);
                }
            }, error: err => super.onError(err)
        });
        this.initDate();
        this.initList();
    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalContactDetail');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    initDate() {
        this.modelSearchContactInput.start = moment().startOf('day').subtract(30, 'days');
        this.modelSearchContactInput.end = moment().endOf('day');
        this.setDatePicker('picker-period', (start, end, label) => {
            this.modelSearchContactInput.start = start.utcOffset(0, true).format();
            this.modelSearchContactInput.end = end.utcOffset(0, true).format();
        });
    }

    initList() {
        this.loading.show();
        this.modelSearchContactInput.page = 0;
        this.modelQuery = this.searchContactGQL.watch({input: this.modelSearchContactInput});
        this.modelQuery.valueChanges.subscribe(({data}) => {
            this.modelSearchContactList = data.searchContact as ContactPage;
            this.loading.hide();
        }, error => super.onError(error));
    }

    actionSearch() {
        this.loading.show();
        this.modelQuery.refetch({input: this.modelSearchContactInput}).finally(() => {
            this.loading.hide();
        });
    }

    pageChanged(event) {
        this.modelSearchContactInput.page = event;
        this.actionSearch();
    }

    actionAnswer() {
        console.log('actionAnswer');
        if (super.isNullOrUndefined(this.model.answer)) {
            this.showMessage('Atenção', `Preencha a resposta para prosseguir!`, 'warning');
            return;
        }
        const r: AnswerContactInput = new AnswerContactInput();
        r.id = this.model.id;
        r.answer = this.model.answer;
        this.asnwerContactGQL.mutate({input: r}).subscribe(({data}) => {
            this.actionSearch();
            $('#modalContactDetail').modal('hide');
            this.showMessage('Sucesso!', `Mensagem respondida com sucesso!`, 'success');
        }, error => super.onError(error));
    }

    actionChangeStatus() {
        this.changeContactStatusGQL.mutate({input: this.model.id}).subscribe(({data}) => {
            this.actionSearch();
            this.showMessage('Sucesso!', `Status alterado com sucesso!`, 'success');
            this.model = Object.assign(new Contact(), data.changeContactStatus);
        }, error => super.onError(error));
    }

    actionHandlerContact(c: Contact) {
        this.model = Object.assign({}, c);
        $('#modalContactDetail').modal('show');
    }

    getExcel() {
        this.excelService.getExcelContact(this.modelSearchContactInput).subscribe({
            next: data => {
                this.downloadHandlerFile(data, 'Contatos');
            }, error: err => super.onError(err)
        });
    }

    handlerCharacteres($event: any, n: number, c: string) {
        const a = $event.substring(0, n);
        this.model.answer = a;
        $(c).val(a);
        $(c).parent().parent().find('.legend').html(a.length + '/' + n);
    }
}
