import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-raffles',
  templateUrl: './raffles.component.html',
  styleUrls: ['./raffles.component.scss']
})
export class RafflesComponent extends BaseComponent implements OnInit {


  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              // private searchUsersGQL: SearchUsersGQL,
  ) {
    super(router,loading, translate);
  }

  ngOnInit(): void {
  }

}
