import {Observable} from 'rxjs';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UploadService extends ServiceBase {

    constructor(http: HttpClient, public userService: UserService) {
        super(http);
    }

    upload(file, urlParameter) {
        const url = `${this.BASE_URL}${urlParameter}`;
        return new Observable((observer) => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
                console.log(progress);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.userService.getToken());
            xhr.send(formData);
        });
    }
}
