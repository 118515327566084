<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Sorteio</h1>
            <a href="javascript:void('');" class="btn btn-info text-white">
                <svg-icon class="svg-white right mr-2" src="assets/svg/excel.svg"></svg-icon>
                Exportar Excel
            </a>
        </div>
        <div class="page-filter">
            <!--<div class="input-material">
                <input id="date" class="form-control picker-period" type="text"
                       required/>
                <label for="date">
                    <svg-icon src="assets/svg/date.svg" class="right"></svg-icon>
                    <span>Período</span>
                </label>
            </div>-->
            <div class="input-material select flex-grow-1">
                <select id="raffle" class="form-control" required>
                    <option disabled value hidden selected></option>
                    <option>A</option>
                    <option>B</option>
                </select>
                <label for="raffle">Sorteio</label>
            </div>
            <div class="input-material">
                <input id="qtdRaffle" class="form-control" type="number" min="1" required />
                <label for="qtdRaffle">
                    <span>Qtde. Sorteados</span>
                </label>
            </div>
            <a class="btn btn-primary btn-lg text-white flex-grow-0">Sortear</a>
        </div>

        <div class="d-block my-5">
            <hr />
        </div>

        <div class="jumbotron">

            <h2>Ganhadores</h2>

            <div class="table-responsive">
                <table class="table table_regular">
                    <thead>
                    <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Telefone</th>
                        <th>Cupom</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="is-cursor" *ngFor="let i of [1]">
                        <td>Nome</td>
                        <td>CPF</td>
                        <td>Telefone</td>
                        <td>00{{i}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <div class="d-block my-5">
            <hr />
        </div>

        <h2>Participantes</h2>

        <div class="table-responsive">
            <table class="table table-striped table_regular">
                <thead>
                <tr>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Telefone</th>
                    <th>Cupom</th>
                </tr>
                </thead>
                <tbody>
                <tr class="is-cursor" *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
                    <td>Nome</td>
                    <td>CPF</td>
                    <td>Telefone</td>
                    <td>00{{i}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>

