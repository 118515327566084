<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Contatos</h1>
            <a href="javascript:void('');" class="btn btn-info text-white" (click)="getExcel()">
                <svg-icon class="svg-white right mr-2" src="assets/svg/excel.svg"></svg-icon>
                Exportar Excel
            </a>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="date" class="form-control picker-period" type="text"
                       required/>
                <label for="date">
                    <svg-icon src="assets/svg/date.svg" class="right"></svg-icon>
                    <span>Período</span>
                </label>
            </div>
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="modelSearchContactInput.keyword"
                       required/>
                <label for="name">
                    <svg-icon src="assets/svg/search.svg"></svg-icon>
                    <span>Nome</span>
                </label>
            </div>
            <div class="input-material select">
                <select id="filterStatus" class="form-control" required [(ngModel)]="modelSearchContactInput.status">
                    <option [ngValue]="undefined" selected>Selecione</option>
                    <option [ngValue]="'pending'">Pendente</option>
                    <option [ngValue]="'answered'">Respondido</option>
                </select>
                <label for="filterStatus">Status</label>
            </div>
            <a class="btn btn-primary btn-lg text-white" (click)="this.modelSearchContactInput.page = 0; actionSearch();">Buscar</a>
            <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>

        </div>

        <div class="table-responsive">
            <table class="table table-striped table_regular">
                <thead>
                <tr>
                    <th class="w-10">Data</th>
                    <th class="w-10">Status</th>
                    <th>Nome</th>
                    <th>Assunto</th>
                    <th>Mensagem</th>
                    <th class="w-5"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="modelSearchContactList?.contact?.length === 0">
                    <td colspan="6" class="py-5">
                        <p class="py-5 text-center mt-5">Nenhum registro encontrado.</p>
                    </td>
                </tr>
                <tr class="is-cursor" *ngFor="let c of modelSearchContactList?.contact |
                paginate: {itemsPerPage: modelSearchContactList.PageSize,
                currentPage: modelSearchContactList.currentPage,
                totalItems: modelSearchContactList.totalCount }">
                    <td>{{c.createdAt | date: 'dd/MM/yyyy - HH:mm'}}</td>
                    <td class="text-capitalize">
                        <span class="badge size-sm" [ngClass]="{'badge-info' : c.status === 'pending', 'badge-success' : c.status === 'answered'}">{{getTranslate(c.status)}}</span>
                    </td>
                    <td>{{c.name}}</td>
                    <td>{{c.subject}}</td>
                    <td>{{c.message}}</td>
                    <td>
                        <a href="javascript:void('');" (click)="actionHandlerContact(c)">
                            <svg-icon class="mr-2 svg-primary" src="assets/svg/eye.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>


<div class="modal-custom modal" id="modalContactDetail" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalContactDetail"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-0">
            <div class="modal-header bg-primary text-white">
                <h3>FALE CONOSCO</h3>
                <a href="javascript:void('Fechar');" class="close text-white" data-dismiss="modal"
                   aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row">
                    <div class="col-md-12 col-lg-10 offset-lg-1 mb-3">
                        <div class="d-flex justify-content-between">
                            <div class="badge badge-info py-1 text-white size-md" *ngIf="model.status !== 'answered'">
                                Pendente
                            </div>
                            <div class="badge badge-success py-1 size-md" *ngIf="model.status === 'answered'">Respondido</div>
                            <div class="custom-control custom-switch">
                                <input (change)="actionChangeStatus()" [checked]="model.status ==='answered'"
                                       type="checkbox" class="custom-control-input"
                                       id="check_b" name="check_b"
                                >
                                <label class="custom-control-label"
                                       for="check_b"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-5 offset-lg-1">
                        <p class="text-muted size-md"><strong>Nome:</strong><span
                                class="ml-2">{{model.name}}</span></p>
                        <p class="text-muted size-md"><strong>Email:</strong> <span
                                class="ml-2">{{model.email}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-5">
                        <p class="text-muted size-md"><strong>Data/Hora:</strong>
                            <span class="ml-2">{{model.createdAt | date: 'dd/MM/yyyy HH:mm'}}</span></p>
                        <p class="text-muted size-md"><strong>Telefone:</strong><span
                                class="ml-2">{{model.phone}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-6 offset-lg-1">
                        <p class="text-muted size-md"><strong>CNPJ</strong>
                            <span class="ml-2">{{model.cnpj}}</span></p>
                    </div>
                    <div class="col-md-12 col-lg-5">
                        <p class="text-muted size-md"><strong>Assunto</strong>
                            <span class="ml-2">{{model.subject}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <p class="text-muted size-md"><strong>Mensagem:</strong><span
                                class="ml-2">{{model.message}}</span></p>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <hr/>
                    </div>

                    <div class="col-md-12 col-lg-10 offset-lg-1">
                        <!--<div class="text-left mb-2">
                            <p class="text-muted size-md"><strong>Resposta:</strong></p>
                        </div>
                        <textarea class=" input-material mb-0" id="description" style="width: inherit; resize: none"
                                  rows="4" [(ngModel)]="model.answer"
                                  [disabled]="!isNullOrUndefined(model.answeredBy)" required></textarea>-->
                        <div class="input-material mb-1" [ngClass]="{'disabled' : model.status === 'answered'}">
                            <textarea  class="form-control js-textarea-contact mt-4" id="description" style="resize: none"
                                      rows="4" [(ngModel)]="model.answer"
                                       (ngModelChange)="handlerCharacteres($event, 2000, '.js-textarea-contact')"
                                      [disabled]="model.status === 'answered'" required></textarea>
                            <label for="description" class="bg-transparent" [ngClass]="{'disabled' : model.status === 'answered'}">Resposta</label>
                        </div>
                        <p class="text-right" *ngIf="model.status !== 'answered'">
                            <small class="legend">0/2000</small>
                        </p>
                    </div>

                    <div class="col-md-12 col-lg-8 offset-lg-2" *ngIf="model.status !== 'answered' && isNullOrUndefined(model.answeredBy)">
                        <div class="mx-auto">
                            <a href="javascript:void(0)" class="btn btn-block btn-warning mt-4 mb-5 px-5"
                               type="button" (click)="actionAnswer()">Enviar</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
