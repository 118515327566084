import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {
  SearchCouponGQL,
  SearchCouponInput, SearchCouponQuery,
  SearchUserAdminInput,
  SearchUserAdminQuery,
  UserAdminPage, UserCouponPage
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent extends BaseComponent implements OnInit, AfterViewInit {

  modelPaginator: UserCouponPage = new UserCouponPage();
  modelQuery: QueryRef<SearchCouponQuery>;

  filter: SearchCouponInput = new SearchCouponInput();
  page = 1;

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              private searchCouponGQL: SearchCouponGQL,
              public userService: UserService
  ) {
    super(router,loading, translate);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.modelQuery = this.searchCouponGQL.watch({input: this.filter});
    this.modelQuery.valueChanges.subscribe(({data}) => {
      this.modelPaginator = data.searchCoupon as UserCouponPage;
    });
  }

  actionSearch() {
    this.loading.show();
    this.modelQuery.refetch({input: this.filter}).finally(() => {
      this.loading.hide();
    });
  }

  pageChanged(event) {
    this.filter.page = event;
    this.actionSearch();
  }

  getExcel() {
    this.userService.getExcelCoupon(this.filter).subscribe({
      next: data => {
        this.downloadHandlerFile(data,'Cupons');
      }, error: err => super.onError(err)
    });
  }

}
