import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
  SearchCouponGQL,
  SearchCouponInput,
  SearchCouponQuery,
  SearchWinnerGQL, SearchWinnerInput, SearchWinnerQuery,
  UserCouponPage, Winner, WinnerPage
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {BaseComponent} from "../../base/base.component";

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.scss']
})
export class WinnersComponent extends BaseComponent implements OnInit, AfterViewInit {

  modelPaginator: WinnerPage = new WinnerPage();
  modelQuery: QueryRef<SearchWinnerQuery>;

  filter: SearchWinnerInput = new SearchWinnerInput();
  page = 1;

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              private searchWinnerGQL: SearchWinnerGQL,
              public userService: UserService
  ) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.modelQuery = this.searchWinnerGQL.watch({input: this.filter});
    this.modelQuery.valueChanges.subscribe(({data}) => {
      this.modelPaginator = data.searchWinner as WinnerPage;
    });
  }

  actionSearch() {
    this.loading.show();
    this.modelQuery.refetch({input: this.filter}).finally(() => {
      this.loading.hide();
    });
  }

  pageChanged(event) {
    this.filter.page = event;
    this.actionSearch();
  }

  getExcel() {
    this.userService.getExcelWinners(this.filter).subscribe({
      next: data => {
        this.downloadHandlerFile(data,'Ganhadores');
      }, error: err => super.onError(err)
    });
  }

}
