<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Cupons</h1>
            <a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
            <a href="javascript:void('');" class="btn btn-info text-white" (click)="getExcel()">
                <svg-icon class="svg-white right mr-2" src="assets/svg/excel.svg"></svg-icon>
                Exportar Excel
            </a>
        </div>
        <div class="page-filter">
            <!--<div class="input-material">
                <input id="date" class="form-control picker-period" type="text"
                       required/>
                <label for="date">
                    <svg-icon src="assets/svg/date.svg" class="right"></svg-icon>
                    <span>Período</span>
                </label>
            </div>-->
            <div class="input-material">
                <input id="name" [(ngModel)]="filter.keyword" class="form-control" type="text"
                       placeholder="Código Pai ou Revenda"
                       required/>
                <label for="name" class="disabled">
                    <span>Busca</span>
                </label>
            </div>
            <div class="input-material select flex-grow-1">
                <select id="filterStatus" class="form-control" required [(ngModel)]="filter.type">
                    <option selected [ngValue]="undefined">Todos</option>
                    <option [ngValue]="'standartization'">Padronização</option>
                    <option [ngValue]="'goal'">Meta</option>
                </select>
                <label for="filterStatus">Tipo</label>
            </div>
            <a class="btn btn-primary btn-lg text-white flex-grow-0"
               (click)="filter.page = 1; actionSearch()">Buscar</a>
        </div>

        <div class="table-responsive">
            <table class="table table-striped table_regular">
                <thead>
                <tr>
                    <th>Data</th>
                    <th>Cupom</th>
                    <th>Tipo</th>
                    <th>Código Pai</th>
                    <th>CNPJ</th>
                    <th>Revenda</th>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>E-mail</th>
                    <!--<th class="w-10"></th>-->
                </tr>
                </thead>
                <tbody>
                <tr class="is-cursor" *ngFor="let c of modelPaginator?.coupons |
                paginate: {itemsPerPage: modelPaginator?.PageSize,
                currentPage: modelPaginator?.currentPage,
                totalItems: modelPaginator?.totalCount }">
                    <td>{{c?.createdAt | date: 'dd/MM/yyyy'}}</td>
                    <td>{{c?.coupon}}</td>
                    <td class="text-capitalize">{{getTranslate(c?.type)}}</td>
                    <td>{{c?.user?.fatherCode}}</td>
                    <td>{{c?.user?.cnpj}}</td>
                    <td>{{c?.user?.companyName}}</td>
                    <td>{{c?.user?.name}}</td>
                    <td>{{c?.user?.cpf}}</td>
                    <td>{{c?.user?.email}}</td>
                    <!--<td class="no-wrap">
                        <a href="javascript:void('');" class="px-1">
                            <svg-icon class="mr-2 svg-primary" src="assets/svg/eye.svg"></svg-icon>
                        </a>
                    </td>-->
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

