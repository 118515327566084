<section class="single-page" >
    <div class="btn-hero" [routerLink]="'/policies-procedures'">
        <svg-icon src="assets/svg/config.svg"></svg-icon>
        <span>Gestão</span>
    </div>
    <div class="btn-hero2" [routerLink]="'/user'">
        <svg-icon src="assets/svg/user-blue.svg"></svg-icon>
        <span>Usuários</span>
    </div>
    <div class="hero">
        <img src="assets/images/background.png" alt="Clic" />
    </div>

    <div class="list-featured">
        <div class="item">
            <a href="javascript:void('');" [routerLink]="'/access-control'">
                <svg-icon src="assets/svg/ocurrences.svg"></svg-icon>
                <span>Ocorrências</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');" [routerLink]="'/access-control'">
                <svg-icon src="assets/svg/camera.svg"></svg-icon>
                <span>Segurança Eletrônica</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:window.open('https://eurofarma.com.br/', '_blank');">
                <svg-icon src="assets/svg/shield.svg"></svg-icon>
                <span>Segurança Fisica</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');" [routerLink]="['/access-control']">
                <svg-icon src="assets/svg/management.svg"></svg-icon>
                <span>Gestão de Riscos</span>
            </a>
        </div>

    </div>
    <div class="list-featured justify-content-center mt-3">
        <div class="item">
            <a href="javascript:void('');">
                <svg-icon src="assets/svg/board.svg"></svg-icon>
                <span>Prancheta Eletrônica</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');">
                <svg-icon src="assets/svg/news.svg"></svg-icon>
                <span>Politicas e Procedimentos</span>
            </a>
        </div>
        <div class="item">
            <a href="javascript:void('');">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span>Dashboard</span>
            </a>
        </div>
    </div>


</section>

<section class="home" *ngIf="false">
    <div class="home_header">
        <h2 class="page_title">CLIC | <span>Dashboard</span></h2>
        <div class="home_divider">&nbsp;</div>
    </div>
</section>


