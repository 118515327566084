<svg-icon class="bell-icon" src="assets/svg/bell.svg"></svg-icon>
<a href="javascript:void(0)">
    <span class="notification_number">{{modelNotifications.length}}</span>
</a>
<div class="header__expanded">
    <ul>
        <li *ngIf="modelNotifications.length === 0"><p class="pt-3 pb-3">Nenhum item encontrado.</p></li>
        <li *ngFor="let n of modelNotifications" (click)="actionNotification(n)">
            <p><strong>{{n.title}}</strong></p>
            <p>{{n.message}}</p>
        </li>
    </ul>
</div>
